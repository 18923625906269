import { requireFun } from '@/utils'
import {
  charLenLimitConstructor,
  isRobust,
  isEmail,
  isTele,
  is1And100char,
  validateSpecialCharacter
} from '@/utils/validate'
import { PROTOTYPE_ALL, PROTOTYPE_APPOINT, PICTURE_ALL, PICTURE_APPOINT } from '@/utils/constant'

const is2And30char = charLenLimitConstructor(2, 30)

export const addUserField = [
  {
    label: '分销商名称',
    prop: 'nickName',
    name: 'nickName',
    colNum: 24,
    placeholder: '请输入分销商名称',
    rule: [
      {
        required: true,
        message: requireFun('分销商名称')
      },
      {
        validator: charLenLimitConstructor(2, 30)
      },
      {
        validator: validateSpecialCharacter
      }
    ]
  },
  {
    label: '手机号',
    prop: 'accountName',
    name: 'accountName',
    colNum: 24,
    placeholder: '请输入手机号',
    rule: [
      {
        required: true,
        message: requireFun('手机号')
      },
      {
        validator: isTele
      }
    ]
  },
  {
    label: '用户邮箱',
    prop: 'email',
    name: 'email',
    colNum: 24,
    placeholder: '请输入用户邮箱',
    rule: [
      {
        required: true,
        message: requireFun('用户邮箱')
      },
      {
        validator: isEmail
      },
      {
        validator: charLenLimitConstructor(8, 50)
      }
    ]
  },
  {
    label: '角色',
    prop: 'roleIdList',
    name: 'roleIdList',
    colNum: 24,
    slotName: 'rolesSlot',
    rule: [
      {
        required: true,
        message: requireFun('角色')
      }
    ]
  },
  {
    label: 'VIP等级',
    prop: 'memberLevelId',
    name: 'memberLevelId',
    colNum: 24,
    slotName: 'memberLevelIdSlot',
    rule: [
      {
        required: true,
        message: requireFun('VIP等级')
      }
    ]
  },
  {
    label: '分销商性质',
    prop: 'userNature',
    name: 'userNature',
    colNum: 24,
    slotName: 'userNatureSlot',
    rule: [
      {
        required: true,
        message: requireFun('分销商性质')
      }
    ]
  },
  {
    label: '密码',
    prop: 'password',
    name: 'password',
    colNum: 24,
    type: 'password',
    placeholder: '请输入密码',
    rule: [
      {
        required: true,
        message: requireFun('密码')
      },
      {
        validator: isRobust
      },
      {
        validator: charLenLimitConstructor(8, 16)
      }
    ]
  },
  {
    label: '确认密码',
    readonly: true,
    prop: 'confirmPassword',
    name: 'confirmPassword',
    colNum: 24,
    type: 'password',
    placeholder: '请输入确认密码',
    rule: [
      {
        required: true,
        message: requireFun('确认密码')
      },
      {
        validator: charLenLimitConstructor(8, 16)
      }
    ]
  },
  {
    label: '账户类别',
    prop: 'isProductPublic',
    name: 'isProductPublic',
    colNum: 24,
    slotName: 'accountTypeSlot',
    rule: [
      {
        required: true,
        message: requireFun('账户类别必选')
      }
    ]
  },
  // {
  //   label: '是否需要支付',
  //   prop: 'isPayOrder',
  //   name: 'isPayOrder',
  //   colNum: 24,
  //   slotName: 'isPayOrderSlot',
  //   rule: [
  //     {
  //       required: true,
  //       message: requireFun('是否需要支付必选')
  //     }
  //   ]
  // },
  {
    label: '原型权限',
    prop: 'prototypePermissionType',
    name: 'prototypePermissionType',
    colNum: 24,
    type: 'radio',
    dicData: [
      {
        label: '所有原型',
        value: PROTOTYPE_ALL
      },
      {
        label: '指定原型',
        value: PROTOTYPE_APPOINT /*,
        cols: [
          {
            label: '原型',
            prop: 'prototypes',
            name: 'prototypes',
            colNum: 24,
            slotName: 'buttonSlot',
            rule: [
              {
                required: true,
                message: requireFun('原型必选')
              }
            ]
          }
        ]*/
      }
    ],
    rule: [
      {
        required: true,
        message: requireFun('原型权限必选')
      }
    ]
  },
  {
    label: '图片权限',
    prop: 'imagePermissionType',
    name: 'imagePermissionType',
    colNum: 24,
    type: 'radio',
    dicData: [
      {
        label: '所有图片',
        value: PICTURE_ALL
      },
      {
        label: '指定图片',
        value: PICTURE_APPOINT /*,
        cols: [
          {
            label: '图片',
            prop: 'pictures',
            name: 'pictures',
            colNum: 24,
            slotName: 'buttonSlot',
            rule: [
              {
                required: true,
                message: requireFun('图片必选')
              }
            ]
          }
        ]*/
      }
    ],
    rule: [
      {
        required: true,
        message: requireFun('图片权限必选')
      }
    ]
  },
  {
    label: '备注',
    prop: 'remark',
    name: 'remark',
    colNum: 24,
    placeholder: '请输入备注',
    rule: [
      {
        required: false,
        message: requireFun('备注')
      },
      {
        validator: is1And100char
      }
    ]
  }
]

export const editUserField = [
  {
    label: '分销商名称',
    prop: 'nickName',
    name: 'nickName',
    colNum: 24,
    placeholder: '请输入分销商名称',
    rule: [
      {
        required: true,
        message: requireFun('分销商名称')
      },
      {
        validator: charLenLimitConstructor(2, 30)
      },
      {
        validator: validateSpecialCharacter
      }
    ]
  },
  {
    label: '登录账号',
    prop: 'accountName',
    name: 'accountName',
    disabled: true,
    colNum: 24
    //placeholder: '请输入手机号'
  },
  {
    label: '用户邮箱',
    prop: 'email',
    name: 'email',
    colNum: 24,
    placeholder: '请输入用户邮箱',
    rule: [
      {
        required: true,
        message: requireFun('用户邮箱')
      },
      {
        validator: isEmail
      }
    ]
  },
  {
    label: '角色',
    prop: 'roleIdList',
    name: 'roleIdList',
    colNum: 24,
    slotName: 'rolesSlot',
    rule: [
      {
        required: true,
        message: requireFun('角色')
      }
    ]
  },
  {
    label: '分销商性质',
    prop: 'userNature',
    name: 'userNature',
    colNum: 24,
    slotName: 'userNatureSlot',
    rule: [
      {
        required: true,
        message: requireFun('分销商性质')
      }
    ]
  },
  {
    label: '原型权限',
    prop: 'prototypePermissionType',
    name: 'prototypePermissionType',
    colNum: 24,
    type: 'radio',
    dicData: [
      {
        label: '所有原型',
        value: 1
      },
      {
        label: '指定原型',
        value: 2
      }
    ],
    rule: [
      {
        required: true,
        message: requireFun('原型权限必选')
      }
    ]
  },
  {
    label: '图片权限',
    prop: 'imagePermissionType',
    name: 'imagePermissionType',
    colNum: 24,
    type: 'radio',
    dicData: [
      {
        label: '所有图片',
        value: 1
      },
      {
        label: '指定图片',
        value: 2
      }
    ],
    rule: [
      {
        required: true,
        message: requireFun('图片权限必选')
      }
    ]
  },
  {
    label: '备注',
    prop: 'remark',
    name: 'remark',
    colNum: 24,
    placeholder: '请输入备注',
    rule: [
      {
        required: false,
        message: requireFun('备注')
      },
      {
        validator: is1And100char
      }
    ]
  }
]
