<template>
  <div class="app-container baseUserRoleComponent app-flex-col-container" v-loading="loading">
    <div class="flex-col-content">
      <div class="title" @click="back">
        <i class="el-icon-d-arrow-left"></i>
        <span>{{ userTitle }}</span>
      </div>
      <BaseForm style="width: 50%" size="small" ref="form" label-width="120PX" :cols="formField" :form="form">
        <template #usernameSlot="{ scoped: { prop } }">
          <el-input v-model="form[prop]" disabled></el-input>
        </template>
        <template #rolesSlot="{ scoped: { prop, placeholder } }">
          <RoleSelectList
            style="width: 250px"
            multiple
            :menus="roleList"
            :keyObj="businessRoleListProps"
            :keyword.sync="form[prop]"
            :placeholder="placeholder"
          />
        </template>
        <template #memberLevelIdSlot="{ scoped: { prop } }">
          <el-select v-model="form[prop]">
            <el-option v-for="item in VIPLevelList" :value="item.id" :label="item.levelName" :key="item.id"></el-option>
          </el-select>
        </template>
        <template #userNatureSlot="{ scoped: { prop } }">
          <el-select v-model="form[prop]">
            <el-option
              v-for="item in userNatureList"
              :value="item.value"
              :label="item.label"
              :key="item.value"
            ></el-option>
          </el-select>
        </template>
        <template #accountTypeSlot="{ scoped: { prop } }">
          <el-radio v-model="form[prop]" label="0">分销商</el-radio>
          <el-radio v-model="form[prop]" label="1">公共产品上新</el-radio>
        </template>
        <template #isPayOrderSlot="{ scoped: { prop } }">
          <el-radio v-model="form[prop]" label="0">否</el-radio>
          <el-radio v-model="form[prop]" label="1">是</el-radio>
        </template>
        <template #buttonSlot="{ col }">
          <el-button type="primary" size="small" @click="buttonClick(col)">
            <template v-if="form[col.prop]">已选择</template>
            <template v-else>选择{{ col.label }}</template>
          </el-button>
          <prototypeDialog :ref="col.prop" v-model="form[col.prop]" :type="col.prop"></prototypeDialog>
        </template>
      </BaseForm>
      <div class="btn-wrapper">
        <el-button @click="back" size="small" v-if="!loading"> 取消 </el-button>
        <el-button type="primary" size="small" @click="doSubmit" :loading="loading"> 提交 </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import RoleSelectList from '../roleSelectList'
import { addUserField, editUserField } from './formField'
import { commonFromMixin } from '@/mixins'
import { add, read, edit } from '@/api/user'
import { deepClone, getDiffData } from '@/components/avue/utils/util'
import prototypeDialog from '@/views/user/module/prototypeDialog'
import { mapGetters } from 'vuex'
import { getVipList } from '@/api/vipLevel'
export default {
  mixins: [commonFromMixin],
  components: {
    RoleSelectList,
    prototypeDialog
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters(['businessRoleList', 'businessRoleListProps']),
    roleList({ businessRoleList, currentRoles }) {
      let businessRoleIds = businessRoleList.map((item) => item.id)
      let tempArr = currentRoles.filter((role) => {
        let isInclude = businessRoleIds.includes(role.id)
        if (!isInclude) {
          role.disabled = true
          return true
        }
      })
      return businessRoleList.concat(tempArr)
    },
    userTitle() {
      return this.isEdit ? '编辑分销商' : '添加分销商'
    },
    formField({ oForm: { isChild } }) {
      let formField = deepClone(this.isEdit ? editUserField : addUserField)
      if (this.isEdit && isChild) {
        let delProps = ['prototypePermissionType', 'imagePermissionType']
        return formField.filter(({ prop }) => !delProps.includes(prop))
      }
      return formField
    }
  },
  data() {
    if (!this.isEdit) {
      const confirmPass = (rule, value, callback) => {
        if (!this.form.password) return
        if (this.form.password !== value) {
          callback(new Error('两次输入的密码不一致'))
        } else {
          this.$refs.form.clearValidate('password')
          callback()
        }
      }

      const confirmPass1 = (rule, value, callback) => {
        if (!this.form.confirmPassword) return
        if (this.form.confirmPassword !== value) {
          callback(new Error('两次输入的密码不一致'))
        } else {
          this.$refs.form.clearValidate('confirmPassword')
          callback()
        }
      }

      let password = addUserField.find(({ prop }) => prop === 'password')
      if (password)
        password.rule[3] = {
          validator: confirmPass1,
          trigger: 'blur'
        }
      let confirmPassword = addUserField.find(({ prop }) => prop === 'confirmPassword')
      if (confirmPassword)
        confirmPassword.rule[2] = {
          validator: confirmPass,
          trigger: 'blur'
        }
    }
    return {
      id: this.$route.query.id,
      form: {},
      oForm: {},
      loading: false,
      currentRoles: [],
      VIPLevelList: [],
      userNatureList: [
        { label: '内部', value: 0 },
        { label: '余额', value: 1 },
        { label: '赊账', value: 2 }
      ]
    }
  },
  async created() {
    this.loading = true
    this.getVipList()
    await this.initEdit()
    this.loading = false
  },
  methods: {
    back() {
      this.$router.back()
    },
    async initEdit() {
      if (!this.isEdit) return
      if (!this.id) {
        this.back()
      }
      const { code, detail } = await read({ id: this.id })
      if ($SUC({ code })) {
        // const { username, roles, nick_name, email, password, password2 } = detail || {}
        let {
          nickName,
          roleDTOList,
          accountType,
          accountName,
          email,
          password,
          userNature,
          confirmPassword,
          imagePermissionType,
          prototypePermissionType,
          remark
        } = detail || {}
        roleDTOList = Array.isArray(roleDTOList) ? roleDTOList : []
        this.currentRoles = roleDTOList
        let roleIdList = roleDTOList.map(({ id }) => id)
        let oForm = (this.form = {
          nickName,
          roleIdList: roleDTOList.map(({ id }) => id),
          accountName,
          email,
          password,
          userNature,
          confirmPassword,
          imagePermissionType,
          prototypePermissionType,
          remark,
          isChild: accountType === 2
        })
        this.oForm = Object.freeze(deepClone(oForm))
        const { form } = this.$refs
        form && form.resetFields()
      }
    },
    async doSubmit() {
      const valid = await this.validate()
      if (!valid) return
      if (this.isEdit) {
        this.doEdit()
      } else {
        this.doAdd()
      }
    },
    async doAdd() {
      this.loading = true
      try {
        const { code } = await add(this.form)
        if ($SUC({ code })) {
          this.success()
          this.back()
        }
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    newAdd(oldRoleArr, nowRoleArr) {
      const newAddArr = []
      for (let i = 0; i < nowRoleArr.length; i++) {
        if (oldRoleArr.indexOf(nowRoleArr[i]) == -1) {
          newAddArr.push(nowRoleArr[i])
        }
      }
      return newAddArr
    },
    newDel(oldRoleArr, nowRoleArr) {
      const newDelArr = []
      for (let i = 0; i < oldRoleArr.length; i++) {
        if (nowRoleArr.indexOf(oldRoleArr[i]) == -1) {
          newDelArr.push(oldRoleArr[i])
        }
      }
      return newDelArr
    },
    async doEdit() {
      this.loading = true
      const nowRoleArr = this.form.roleIdList
      const oldRoleArr = this.currentRoles.map((item) => {
        return item.id
      })
      const addArr = this.newAdd(oldRoleArr, nowRoleArr)
      const delArr = this.newDel(oldRoleArr, nowRoleArr)
      const emptObj = deepClone(this.form)
      // emptObj.id = this.id
      delete emptObj.roleIdList
      delete emptObj.roleDTOList
      emptObj.createRoleIdList = addArr
      emptObj.delRoleIdList = delArr
      try {
        let diffData = getDiffData(
          emptObj,
          Object.assign({}, this.oForm, {
            createRoleIdList: [],
            delRoleIdList: []
          })
        )
        console.log(emptObj, diffData)
        let code = 200
        if (diffData) {
          emptObj.id = this.id
          delete emptObj.password
          delete emptObj.isChild
          code = (await edit(emptObj)).code
        }

        if ($SUC({ code })) {
          this.success()
          this.back()
        }
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    async getVipList() {
      const { detail } = await getVipList()
      this.VIPLevelList = detail || []
    },

    buttonClick(col) {
      this.$refs[col.prop].dialogOpen()
    }
  }
}
</script>

<style lang="scss" scoped>
.baseUserRoleComponent {
  padding: 30px;
  .title {
    padding: 15px;
    background: #fff;
    i {
      margin-right: 10px;
    }
  }

  .btn-wrapper {
    margin-top: 30px;
    margin-left: 100px;
    padding-left: 15px;
    text-align: left;
  }
}
</style>
