var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"app-container baseUserRoleComponent app-flex-col-container"},[_c('div',{staticClass:"flex-col-content"},[_c('div',{staticClass:"title",on:{"click":_vm.back}},[_c('i',{staticClass:"el-icon-d-arrow-left"}),_c('span',[_vm._v(_vm._s(_vm.userTitle))])]),_c('BaseForm',{ref:"form",staticStyle:{"width":"50%"},attrs:{"size":"small","label-width":"120PX","cols":_vm.formField,"form":_vm.form},scopedSlots:_vm._u([{key:"usernameSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}})]}},{key:"rolesSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('RoleSelectList',{staticStyle:{"width":"250px"},attrs:{"multiple":"","menus":_vm.roleList,"keyObj":_vm.businessRoleListProps,"keyword":_vm.form[prop],"placeholder":placeholder},on:{"update:keyword":function($event){return _vm.$set(_vm.form, prop, $event)}}})]}},{key:"memberLevelIdSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('el-select',{model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}},_vm._l((_vm.VIPLevelList),function(item){return _c('el-option',{key:item.id,attrs:{"value":item.id,"label":item.levelName}})}),1)]}},{key:"userNatureSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('el-select',{model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}},_vm._l((_vm.userNatureList),function(item){return _c('el-option',{key:item.value,attrs:{"value":item.value,"label":item.label}})}),1)]}},{key:"accountTypeSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('el-radio',{attrs:{"label":"0"},model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}},[_vm._v("分销商")]),_c('el-radio',{attrs:{"label":"1"},model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}},[_vm._v("公共产品上新")])]}},{key:"isPayOrderSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('el-radio',{attrs:{"label":"0"},model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}},[_vm._v("否")]),_c('el-radio',{attrs:{"label":"1"},model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}},[_vm._v("是")])]}},{key:"buttonSlot",fn:function(ref){
var col = ref.col;
return [_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.buttonClick(col)}}},[(_vm.form[col.prop])?[_vm._v("已选择")]:[_vm._v("选择"+_vm._s(col.label))]],2),_c('prototypeDialog',{ref:col.prop,attrs:{"type":col.prop},model:{value:(_vm.form[col.prop]),callback:function ($$v) {_vm.$set(_vm.form, col.prop, $$v)},expression:"form[col.prop]"}})]}}])}),_c('div',{staticClass:"btn-wrapper"},[(!_vm.loading)?_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.back}},[_vm._v(" 取消 ")]):_vm._e(),_c('el-button',{attrs:{"type":"primary","size":"small","loading":_vm.loading},on:{"click":_vm.doSubmit}},[_vm._v(" 提交 ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }